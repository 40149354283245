import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://68be9e0e4902c3e47c204a8812ec7cf3@o162127.ingest.us.sentry.io/4507129758679040",
  debug: false,
  environment: "prod",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});